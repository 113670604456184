<template>
  <div class="flex flex-col gap-y-2">

    <div id="sales-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="order-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">
              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_from"
                labelAddon="Od"
                :markDirty="true"
                :error="saveErrors.date_from"
              />

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.date_to"
                labelAddon="Do"
                :markDirty="true"
                :error="saveErrors.date_to"
              />

              <div class="hidden sm:flex w-full md:min-w-max flex-1 items-center">
                <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-left" size="lg" />
                </button>
                <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
                <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
                  <FontAwesomeIcon icon="chevron-right" size="lg" />
                </button>
              </div>

            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex-grow sm:hidden flex items-center">
            <button @click="prevMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-left" size="lg" />
            </button>
            <button @click="setToday" class="tw--btn tw--btn-secondary">dzisiaj</button>
            <button @click="nextMonth" class="tw--link text-gray-400 dark:text-gray-500 px-2 py-0.5 focus:outline-none focus:ring-1 focus:ring-blue-100 dark:ring-gray-700">
              <FontAwesomeIcon icon="chevron-right" size="lg" />
            </button>
          </div>
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="init" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
      </div>
    </div>
    <div id="sales-report-content" class="p-3 pt-1 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex flex-col">
        <span class="font-semibold">Raport skuteczności asystenki(-a) wg. daty wystawienia polisy / odrzucenia oferty</span>
      </div>

      <hr class="dark:border-gray-600 -mx-3">

      <div class="flex flex-wrap gap-x-5 gap-y-1 mt-2">

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">przypis razem</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ reportSum.przypis | moneyPL }}</span>
        </div>

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">wystawionych</span>
          <span class="px-3 py-1 rounded-r bg-indigo-500 dark:bg-indigo-900">{{ reportSum.sold }}</span>
        </div>

        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">odrzuconych</span>
          <span class="px-3 py-1 rounded-r bg-purple-500 dark:bg-purple-900">{{ reportSum.rejected }}</span>
        </div>

      </div>
      <p v-if="przypisAlert" class="text-sm italic text-muted">uwaga - przypis może być niaktualny, jesli składki w polisach wystawionych nie są uzupełnione</p>

      <div class="flex flex-wrap">
        <div class="w-full xl:w-4/5 pr-5" :class="currentUserType === 'root' || currentUserType === 'admin' ? ' 2xl:w-4/6' : ' 2xl:w-3/5'">
          <div class="hidden lg:flex border-b border-gray-400 dark:border-gray-600 font-semibold">
            <div class="flex-grow">Asystent(ka)</div>
            <div class="w-28 text-right">przypis</div>
            <div class="w-28 text-right">wystawiono</div>
            <div class="w-28 text-right">odrzucono</div>
            <div class="w-24 text-right">razem</div>
            <div class="w-32 text-right">skuteczność</div>
            <div class="w-12 text-center"></div>
            <div v-if="currentUserType === 'root' || currentUserType === 'admin'" class="w-24 text-right">podjęto</div>
            <div v-if="currentUserType === 'root' || currentUserType === 'admin'" class="w-12 text-center"></div>
          </div>
          <div v-if="report.length === 0" class="mt-2 italic text-muted">
            brak pozycji spełniających wybrane kryteria
          </div>
          <div v-for="(row, index) in report"
            :key="index"
            class="flex flex-col lg:flex-row border-b border-gray-900 lg:border-gray-400 dark:border-gray-400 lg:dark:border-gray-700 border-solid lg:border-dashed"
          >
            <div class="flex-grow flex justify-between lg:justify-start items-center">
              <div>{{ row.copywriter_name }}</div>
              <a v-if="Number(row.sum_all) > 0" class="lg:hidden tw--link" :href="`${exportCsvUrl}${row.id}`" target="_blank">
                <FontAwesomeIcon icon="cloud-download-alt" size="lg" />
              </a>
            </div>
            <div class="flex-shrink-0 w-full lg:w-28 flex justify-between lg:justify-end items-center">
              <div class="block lg:hidden text-muted">przypis</div><div>{{ row.sum_przypis | moneyPL }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-28 flex justify-between lg:justify-end items-center">
              <div class="block lg:hidden text-muted">wystawiono</div><div>{{ row.sum_sold }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-28 flex justify-between lg:justify-end items-center">
              <div class="block lg:hidden text-muted">odrzucono</div><div>{{ row.sum_rejected }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-24 flex justify-between lg:justify-end items-center">
              <div class="block lg:hidden text-muted">razem</div><div>{{ Number(row.sum_sold) + Number(row.sum_rejected) }}</div>
            </div>
            <div class="flex-shrink-0 w-full lg:w-32 flex justify-between lg:justify-end items-center">
              <div class="block lg:hidden text-muted">skuteczność</div><div>{{ row.efficiency | percentPL }}%</div>
            </div>
            <div class="hidden lg:flex flex-shrink-0 w-full lg:w-12 justify-between lg:justify-center items-center">
              <a class="tw--link" :href="`${exportCsvUrl}${row.copywriter_id}`" target="_blank">
                <FontAwesomeIcon icon="cloud-download-alt" />
              </a>
            </div>
            <div v-if="currentUserType === 'root' || currentUserType === 'admin'" class="flex-shrink-0 w-full lg:w-24 flex justify-between lg:justify-end items-center border-t border-gray-600 dark:border-gray-400 border-dashed lg:border-t-0">
              <div class="block lg:hidden text-muted">
                podjęto
                <a v-if="Number(row.sum_taken) > 0" class="tw--link" :href="`${exportCsvUrl1}${row.copywriter_id}`" target="_blank">
                  <FontAwesomeIcon icon="cloud-download-alt" />
                </a>
                <FontAwesomeIcon v-else class="text-gray-300 dark:text-gray-700" icon="cloud-download-alt" />
              </div>
              <div>{{ row.sum_taken }}</div>
            </div>
            <div v-if="currentUserType === 'root' || currentUserType === 'admin'" class="hidden lg:flex flex-shrink-0 w-full lg:w-12 justify-between lg:justify-center items-center">
              <a v-if="Number(row.sum_taken) > 0" class="tw--link" :href="`${exportCsvUrl1}${row.copywriter_id}`" target="_blank">
                <FontAwesomeIcon icon="cloud-download-alt" />
              </a>
              <FontAwesomeIcon v-else class="text-gray-300 dark:text-gray-700" icon="cloud-download-alt" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { errorHandler } from '../../../mixins/errorHandler';
import moment from 'moment';
import EximaService from '../../../services/EximaService';

export default {
  name: 'excAssistantEfficiencyReport',
  mixins: [errorHandler],
  data() {
    return {
      report: [],
      activeFilters: {
        date_from: '',
        date_to: '',
      },
      saveErrors: {},
    };
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    reportSum() {
      return this.report.reduce((sumObj, currObj) => {
        return {
          przypis: sumObj.przypis + Number(currObj.sum_przypis),
          sold: sumObj.sold + Number(currObj.sum_sold),
          rejected: sumObj.rejected + Number(currObj.sum_rejected),
        };
      }, { przypis: 0, sold: 0, rejected: 0 });
    },
    przypisAlert() {
      return this.activeFilters.date_to > moment().subtract(10, 'days').format('YYYY-MM-DD');
    },
    exportCsvUrl() {
      let params = '?';
      Object.keys(this.$route.query).forEach(key => {
        if (key !== 'employee_id') {
          params = `${params}${key}=${this.$route.query[key]}&`;
        }
      });
      return `${process.env.VUE_APP_API_URL}/api/exclusive-reports/exima/efficiency-report-to-csv/${params}subject=wystawiajacy&subject_id=`;
    },
    exportCsvUrl1() {
      let params = '?';
      Object.keys(this.$route.query).forEach(key => {
        if (key !== 'employee_id') {
          params = `${params}${key}=${this.$route.query[key]}&`;
        }
      });
      return `${process.env.VUE_APP_API_URL}/api/exclusive-reports/exima/taken-report-to-csv/${params}assistant_id=`;
    },
  },
  mounted() {
    if (this.currentUserType !== 'root' && this.currentUserType !== 'admin' && this.currentUserType !== 'asystent' && this.currentUserType !== 'pracownik_b') {
      this.$router.push({name: 'Reports'});
      this.$store.dispatch('notifications/add', { type: 'danger', message: 'Brak uprawnień do modułu' });
    } else {
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
      this.init();
    }
  },
  methods: {
    init() {
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      this.saveErrors = {};
      EximaService.getEfficiencyReport({ ...this.activeFilters, subject: 'wystawiajacy'})
       .then((response) => {
          this.report = response.data.result;
          this.setUrl();
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
    prevMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
    },
    setToday() {
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
    },
    nextMonth() {
      this.activeFilters.date_from = moment(this.activeFilters.date_from).add(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment(this.activeFilters.date_from).endOf('month').format('YYYY-MM-DD');
    },
    setUrl() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(this.activeFilters)) {
        this.$router.replace({ name: 'EximaAssistantEfficiencyReport', query: this.activeFilters });
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.activeFilters.date_from = moment().startOf('month').format('YYYY-MM-DD');
      this.activeFilters.date_to = moment().endOf('month').format('YYYY-MM-DD');
      this.getReport();
    },
  },
};
</script>
